import "./contact.css";
import { useSelector } from "react-redux";
export default function Contact() {
  const { imgUrl, visibility } = useSelector((state) => state.inf);

  return (
    <div className={`section `}>
      <div className="section-center">
        <div className="contact">
          <h2>Contact Information:</h2>
          <h3>
            <i className="fas fa-envelope"></i> Email: Soudeh.bayat@gmail.com
          </h3>
          <h3>
            <i className="fas fa-map-marker-alt"></i> Address: Ottawa, Canada
          </h3>
          <h3>
            <i className="fas fa-envelope"></i> Follow me on:
          </h3>
          <p>
            <a
              className="socialContacts"
              target="_blank"
              href="https://www.linkedin.com/in/soudeh-bayat-a3b23238/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              className="socialContacts"
              target="_blank"
              href="https://www.instagram.com/soudehbyt/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="socialContacts"
              target="_blank"
              href="https://www.facebook.com/soudeh.bayat"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useRef, useState } from "react";
import Item from "./Item";
import { proxy, snapshot } from "valtio";
import { useFrame } from "@react-three/fiber";
import { useSelector } from "react-redux";

const state = proxy({
  clicked: null,
  urls: [0, 1, 2, 3, 4, 5, 6, 7, 8],
});
export default function Items({ position = [0, 0, -2] }) {
  const { rotation } = useSelector((state) => state.gallery);
  const [rotationDelta, setRotationDelta] = useState(0);
  const ref = useRef();
  const { clicked, urls } = snapshot(state);

  useFrame((state, delta) => {
    if (!rotation) {
    } else {
      ref.current.rotation.y += delta * Math.PI * 0.02;
      setRotationDelta(ref.current.rotation.y);
    }
  });
  const itemsRadius = 4;
  return (
    <group ref={ref} position={position}>
      {urls.map((url, index) => (
        <Item
          key={index}
          url={`images/${url}.jpg`}
          position={[
            itemsRadius * Math.sin((index * 2 * Math.PI) / urls.length),
            0,
            itemsRadius * Math.cos((index * 2 * Math.PI) / urls.length),
          ]}
          rotationY={[(index * 2 * Math.PI) / urls.length]}
          scale={[2, 4]}
          index={index}
          rotationDelta={rotationDelta}
        />
      ))}
    </group>
  );
}

import "./hero.css";

export default function Hero(data) {
  const handleClickScroll = (pageClassName) => {
    const element = document.querySelector(`.${pageClassName}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="section section-one">
      <div className="section-center">
        <div className="aboutMeHero">
          <img src="images/portfolio/soudeh.png" />
          <h1>Soudeh Bayat</h1>
          <h2>Traditional Persian Painting Artist</h2>
          <div className="buttoms">
            <h2
              onClick={() => {
                handleClickScroll("aboutMe");
              }}
            >
              About Me
            </h2>
            <h2
              onClick={() => {
                handleClickScroll("contact");
              }}
            >
              Contact
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import galleryReducer from "./gallery.js";
import infReducer from "./inf.js";

const store = configureStore({
  reducer: {
    gallery: galleryReducer,
    inf: infReducer,
  },
});

export default store;

import { createSlice } from "@reduxjs/toolkit";

export const infSlice = createSlice({
  name: "inf",
  initialState: {
    visibility: "invisible",
    imgUrl: "1",
  },
  reducers: {
    setInfUrl: (state, action) => {
      state.imgUrl = action.payload;
    },
    setInfVisible: (state) => {
      state.visibility = "visible";
    },
    setInfInvisible: (state) => {
      state.visibility = "invisible";
    },
  },
});

export const { setInfUrl, setInfVisible, setInfInvisible } = infSlice.actions;
export default infSlice.reducer;
